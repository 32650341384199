import * as r3 from 'fhir/r3';

export const BLOOD_PRESSURE_TEMPLATE: r3.Observation = {
  resourceType: 'Observation',

  id: '$UUID_CREATED_FOR_THE_RESOURCE',
  identifier: [
    {
      system: 'urn:oid:$OID_FOR_DM_PRODUCT',
      value: 'urn:uuid:$UUID_CREATED_FOR_THE_RESOURCE',
    },
  ],
  effectiveDateTime: '$DATETIME_COLLECTED_FROM_USER',
  category: [
    {
      coding: [
        {
          system: 'http://hl7.org/fhir/observation-category',
          code: 'vital-signs',
          display: 'Vital Signs',
        },
      ],
    },
  ],
  subject: {
    display: 'Patient',
    reference: 'Patient/urn:uuid:$UUID_FOR_PATIENT',
  },
  performer: [
    {
      display: 'Patient',
      reference: 'Patient/urn:uuid:$UUID_FOR_PATIENT',
    },
  ],
  meta: {
    profile: [
      'http://nictiz.nl/fhir/StructureDefinition/zib-BloodPressure',
      'http://hl7.org/fhir/3.0/StructureDefinition/Observation',
    ],
  },
  component: [
    {
      code: {
        coding: [
          {
            system: 'http://loinc.org',
            code: '8480-6',
            display: 'Systolic blood pressure',
          },
        ],
      },
      valueQuantity: {
        value: '$VALUE_SYSTOLIC' as any,
        unit: 'mmHg',
        system: 'http://unitsofmeasure.org',
        code: 'mm[Hg]',
      },
    },
    {
      code: {
        coding: [
          {
            system: 'http://loinc.org',
            code: '8462-4',
            display: 'Diastolic blood pressure',
          },
        ],
      },
      valueQuantity: {
        value: '$VALUE_DIASTOLIC' as any,
        unit: 'mmHg',
        system: 'http://unitsofmeasure.org',
        code: 'mm[Hg]',
      },
    },
  ],
  code: {
    coding: [
      {
        system: 'http://loinc.org',
        code: '85354-9',
        display: 'Blood pressure panel with all children optional',
      },
    ],
  },
  status: 'final',
};
