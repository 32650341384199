import * as r3 from 'fhir/r3';

export const HEIGHT_TEMPLATE: r3.Observation = {
  resourceType: 'Observation',

  id: '$UUID_CREATED_FOR_THE_RESOURCE',
  identifier: [
    {
      system: 'urn:oid:$OID_FOR_DM_PRODUCT',
      value: 'urn:uuid:UUID_CREATED_FOR_THE_RESOURCE',
    },
  ],
  effectiveDateTime: '$DATETIME_COLLECTED_FROM_USER',
  category: [
    {
      coding: [
        {
          display: 'Vital Signs',
          system: 'http://hl7.org/fhir/observation-category',
          code: 'vital-signs',
        },
      ],
    },
  ],
  subject: {
    display: 'Patient',
    reference: 'Patient/urn:uuid:$UUID_FOR_PATIENT',
  },
  performer: [
    {
      display: 'Patient',
      reference: 'Patient/urn:uuid:$UUID_FOR_PATIENT',
    },
  ],
  // This is example optional data
  //comment: '$COMMENT_COLLECTED_FROM_USER',
  status: 'final',
  meta: {
    profile: [
      'http://nictiz.nl/fhir/StructureDefinition/zib-BodyHeight',
      'http://hl7.org/fhir/3.0/StructureDefinition/Observation',
    ],
  },
  valueQuantity: {
    code: '$UNIT',
    value: '$VALUE' as any,
    unit: '$UNIT',
    system: 'http://unitsofmeasure.org',
  },
  code: {
    coding: [
      {
        system: 'http://loinc.org',
        code: '8302-2',
        display: 'Body height',
      },

      // This is example optional data
      // {
      //   system: 'http://loinc.org',
      //   code: '8308-9',
      //   display: 'Body height standing',
      // },
      // {
      //   system: 'http://loinc.org',
      //   code: '8306-3',
      //   display: 'Body height lying',
      // },
    ],
  },
};
