<div ngbAccordion
     #acc="ngbAccordion"
     class="accordion-flush"
     *ngFor="let item of menu">
  <div ngbAccordionItem>
    <a ngbAccordionHeader
       class="accordion-button custom-header accordion-header-item icon-link"
       [routerLinkActive]="['active']"
       [routerLink]="item.path"
       (click)="select(item)">
      <svg data-cy="header-icon"
           class="d-inline-block menu-icon"
           aria-hidden="true">
        <use attr.xlink:href="./assets/images/assets.svg#{{item.icon}}"></use>
      </svg>
      {{ item.header }}
    </a>

    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>