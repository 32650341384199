import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppleHealthUploadService } from '@core/services';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { SELF_MEASUREMENT_ACTIONS } from '@store/app';
import {
  AUTHORIZE_URL_API_ACTIONS,
  selectAllFilesProcessedSuccessfully,
  selectAnyFileProcessedFailed,
  STORAGE_FILE_API_ACTIONS,
} from '@store/digi.me';
import { filter } from 'rxjs';
import { MenuService } from '../../menu';
import { AddDropDownMenuComponent } from './add-drop-down-menu.component';

@Component({
  standalone: true,
  imports: [AddDropDownMenuComponent, SharedModule],
  selector: 'app-add-drop-down-menu-container',
  template: `<app-add-drop-down-menu
    (linkSource)="linkSource()"
    (addAppleHealth)="addAppleHealth()"
    (addMeasurement)="addMeasurement($event)"
    [menuItem]="menuService.selectMeasurementMenuItem$ | async"></app-add-drop-down-menu> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDropDownMenuContainerComponent {
  anyFileProcessedFailed$ = this.store.select(selectAnyFileProcessedFailed);
  allFilesProcessedSuccessfully$ = this.store.select(selectAllFilesProcessedSuccessfully);

  constructor(
    private readonly store: Store,
    readonly menuService: MenuService,
    readonly appleHealthUploadService: AppleHealthUploadService,
    readonly destroyRef: DestroyRef,
  ) {
    this.allFilesProcessedSuccessfully$
      .pipe(
        takeUntilDestroyed(destroyRef),
        filter((allSuccess) => allSuccess),
      )
      .subscribe(() => this.store.dispatch(STORAGE_FILE_API_ACTIONS.appleHealthStorageFilesSucceeded()));

    this.anyFileProcessedFailed$
      .pipe(
        takeUntilDestroyed(destroyRef),
        filter((anyFailed) => anyFailed),
      )
      .subscribe(() => this.store.dispatch(STORAGE_FILE_API_ACTIONS.appleHealthStorageFilesFailed()));
  }

  addAppleHealth() {
    this.appleHealthUploadService.addAppleHealth();
  }

  public linkSource(): void {
    this.store.dispatch(
      AUTHORIZE_URL_API_ACTIONS.authorizeUrlRequested({ createNewUser: false, sourceFetch: true, sourceType: 'pull' }),
    );
  }

  public addMeasurement(measurementType: string | undefined): void {
    this.store.dispatch(SELF_MEASUREMENT_ACTIONS.add({ measurementType: measurementType }));
  }
}
