import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-help',
  imports: [SharedModule, ButtonComponent],
  template: `
    <app-button
      type="secondary"
      (clicked)="select()"
      data-cy="help-button"
      use="menu"
      icon="help"
      [href]="helpLink"
      target="_blank"
      i18n-label="@@app.help"
      label="Help"></app-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpComponent {
  @Output() selected = new EventEmitter();

  helpLink = $localize.locale == 'nl' ? 'https://digi.me/nl/questions' : 'https://digi.me/questions';
  select() {
    this.selected.emit();
  }
}
