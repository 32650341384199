<app-modal-stepper #stepper
                   [selectedIndex]="0">
  <app-modal-step>
    <app-modal-step-header>
      <h2 class="fw-bold"
          i18n="@@app.self-measurements.modal.title">
        What measurement would you like to record?
      </h2>
    </app-modal-step-header>
    <app-modal-step-body>
      <app-self-measurements-menu (selected)="selected($event)"></app-self-measurements-menu>
    </app-modal-step-body>
    <app-modal-step-footer>
      <app-button use="block"
                  type="secondary"
                  label="Cancel"
                  i18n-label="@@app.modal.button.cancel"
                  (clicked)="stepper.close('Cancel click')">
      </app-button>
    </app-modal-step-footer>
  </app-modal-step>
  <app-modal-step [component]="measurementComponent"> </app-modal-step>
</app-modal-stepper>