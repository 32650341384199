import * as r3 from 'fhir/r3';

export const RESPIRATION_TEMPLATE: r3.Observation = {
  resourceType: 'Observation',
  id: '$UUID_CREATED_FOR_THE_RESOURCE',
  identifier: [
    {
      system: 'urn:oid:$OID_FOR_DM_PRODUCT',
      value: 'urn:uuid:$UUID_CREATED_FOR_THE_RESOURCE',
    },
  ],
  effectiveDateTime: '$DATETIME_COLLECTED_FROM_USER',
  category: [
    {
      coding: [
        {
          system: 'http://hl7.org/fhir/observation-category',
          code: 'vital-signs',
          display: 'Vital Signs',
        },
      ],
    },
  ],
  subject: {
    display: 'Patient',
    reference: 'Patient/urn:uuid:$UUID_FOR_PATIENT',
  },
  performer: [
    {
      display: 'Patient',
      reference: 'Patient/urn:uuid:$UUID_FOR_PATIENT',
    },
  ],
  meta: {
    profile: [
      'http://hl7.org/fhir/3.0/StructureDefinition/Observation',
      'http://nictiz.nl/fhir/StructureDefinition/zib-Respiration',
    ],
  },
  component: [
    {
      code: {
        coding: [
          {
            system: 'http://loinc.org',
            code: '9279-1',
            display: 'Respiratory rate',
          },
        ],
      },
      valueQuantity: {
        value: parseFloat('$FREQUENCY_VALUE'),
        unit: 'breaths per minute',
        system: 'http://unitsofmeasure.org',
        code: '{breaths}/min',
      },
    },
    {
      code: {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '48064009',
            display: 'Respiratory rhythm',
          },
        ],
      },
      valueCodeableConcept: {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '$RHYTHM_CODE',
            display: '$RHYTHM_DISPLAY',
          },
        ],
      },
    },
    {
      code: {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '271626009',
            display: 'Depth of respiration',
          },
        ],
      },
      valueCodeableConcept: {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '$DEPTH_CODE',
            display: '$DEPTH_DISPLAY',
          },
        ],
      },
    },
    {
      code: {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '278907009',
            display: 'Respiratory pattern',
          },
        ],
      },
      valueCodeableConcept: {
        coding: [
          {
            system: '$PATTERN_SYSTEM',
            code: '$PATTERN_CODE',
            display: '$PATTERN_DISPLAY',
          },
        ],
      },
    },
  ],
  code: {
    coding: [
      {
        system: 'http://snomed.info/sct',
        code: '422834003',
        display: 'Respiratory assessment',
      },
    ],
  },
  status: 'final',
};
