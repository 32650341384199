import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { limitAndFormatDecimal, limitAndFormatNumber, nameof } from '@globals';
import { Coding } from '@hl7fhir';
import { ValueSets } from '@hl7fhir/value-sets';
import {
  AfwijkendAdemhalingspatroonCodelijst,
  DiepteCodelijst,
  JaNeeCodelijst,
  RitmeCodelijst,
} from '@hl7nl-fhir/value-sets';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-respiration-rate-entry',
  templateUrl: './respiration-rate-entry.component.html',
  imports: [SharedModule, ButtonComponent],
})
export class RespirationRateEntryComponent {
  public static afwijkendAdemhalingspatroonCodelijstCodings: Coding[] = [
    { system: 'http://snomed.info/sct', code: '414563008', display: "Kussmaul's respiration" },
    { system: 'http://snomed.info/sct', code: '309155007', display: 'Cheyne-Stokes respiration' },
    { system: 'http://snomed.info/sct', code: '39106000', display: 'Ataxic respiration' },
    { system: 'http://snomed.info/sct', code: '70185004', display: 'Apneustic breathing' },
    { system: 'http://snomed.info/sct', code: '7013003', display: 'Cluster breathing' },
    { system: 'http://snomed.info/sct', code: '1023001', display: 'Apnoea' },
    { system: 'http://snomed.info/sct', code: '248586000', display: 'Prolonged expiration' },
    { system: 'http://snomed.info/sct', code: '23141003', display: 'Gasping respiration' },
    { system: 'http://hl7.org/fhir/v3/NullFlavor', code: 'OTH', display: 'Other' },
  ];

  public static diepteCodelijstCodings: Coding[] = [
    { system: 'http://snomed.info/sct', code: '301284009', display: 'Normal depth of breathing' },
    { system: 'http://snomed.info/sct', code: '386616007', display: 'Shallow breathing' },
    { system: 'http://snomed.info/sct', code: '289123006', display: 'Deep breathing' },
    { system: 'http://snomed.info/sct', code: '248587009', display: 'Depth of respiration varies' },
  ];

  public static ritmeCodelijstCodings: Coding[] = [
    { system: 'http://snomed.info/sct', code: '5467003', display: 'Normal respiratory rhythm' },
    { system: 'http://snomed.info/sct', code: '85617008', display: 'Abnormal respiratory rhythm' },
  ];

  public static jaNeeCodelijstCodings: Coding[] = [
    { system: 'http://hl7.org/fhir/v2/0136', code: 'Y', display: 'Yes' },
    { system: 'http://hl7.org/fhir/v2/0136', code: 'N', display: 'No' },
  ];

  @Input({ required: true }) form!: FormGroup;

  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>();

  readonly afwijkendAdemhalingspatroonCodelijst = AfwijkendAdemhalingspatroonCodelijst;
  readonly diepteCodelijst = DiepteCodelijst;
  readonly ritmeCodelijst = RitmeCodelijst;
  readonly jaNeeCodelijst = JaNeeCodelijst;
  readonly translatedAfwijkendAdemhalingspatroonCodelijst: Coding[];
  readonly translatedDiepteCodelijst: Coding[];
  readonly translatedRitmeCodelijst: Coding[];
  readonly translatedJaNeeCodelijst: Coding[];

  constructor() {
    this.translatedAfwijkendAdemhalingspatroonCodelijst =
      RespirationRateEntryComponent.afwijkendAdemhalingspatroonCodelijstCodings.map((coding) => {
        const display = ValueSets.getDisplay(coding, [this.afwijkendAdemhalingspatroonCodelijst]);
        return { ...coding, display: display.code } as Coding;
      });
    this.translatedDiepteCodelijst = RespirationRateEntryComponent.diepteCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [this.diepteCodelijst]);
      return { ...coding, display: display.code } as Coding;
    });
    this.translatedRitmeCodelijst = RespirationRateEntryComponent.ritmeCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [this.ritmeCodelijst]);
      return { ...coding, display: display.code } as Coding;
    });
    this.translatedJaNeeCodelijst = RespirationRateEntryComponent.jaNeeCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [this.jaNeeCodelijst]);
      return { ...coding, display: display.code } as Coding;
    });
  }

  get maxDay(): string {
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOString = new Date(Date.now() - tzoffset).toISOString();
    const dateString = localISOString.substring(0, 16);

    return dateString;
  }

  get dateControl() {
    return this.form.get(nameof<RespirationRateEntryComponent>('dateControl'));
  }

  get breathsPerMinuteControl(): any {
    return this.form.get(nameof<RespirationRateEntryComponent>('breathsPerMinuteControl'));
  }

  get flowRateControl(): any {
    return this.form.get(nameof<RespirationRateEntryComponent>('flowRateControl'));
  }

  get inspiredValueControl(): any {
    return this.form.get(nameof<RespirationRateEntryComponent>('inspiredValueControl'));
  }

  get oxygenAdministeredControl(): any {
    return this.form.get(nameof<RespirationRateEntryComponent>('oxygenAdministeredControl'));
  }

  /**
   * Limits the input value of a target element to a maximum available number and a specific decimal pattern.
   * @param target - The target element that triggered the event.
   */
  breathsPerMinuteControlInput(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    const value = limitAndFormatNumber(input.value, 999, 0);
    this.breathsPerMinuteControl!.setValue(value);
  }

  flowRateControlInput(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    const value = limitAndFormatDecimal(input.value, 50.0, 0.0);
    this.flowRateControl!.setValue(value);
  }

  inspiredValueControlInput(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    const value = limitAndFormatDecimal(input.value, 1.0, 0.0);
    this.inspiredValueControl!.setValue(value);
  }
}
