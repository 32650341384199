import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Device } from '@capacitor/device';
import { environment } from '@environments/environment';
import { ButtonComponent } from '@layout';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SelfMeasurementsModalComponent } from '@self-measurements';
import { SharedModule } from '@shared';
import { MenuItem } from '../../menu';

@Component({
  standalone: true,
  selector: 'app-add-drop-down-menu',
  templateUrl: './add-drop-down-menu.component.html',
  imports: [NgbDropdownModule, ButtonComponent, SharedModule, SelfMeasurementsModalComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDropDownMenuComponent implements OnInit {
  @ViewChild('drop', { static: true }) dropdown: NgbDropdown | undefined;

  @Input({ required: false }) menuItem: MenuItem | null | undefined;

  @Output() linkSource = new EventEmitter();
  @Output() addMeasurement = new EventEmitter<string | undefined>();
  @Output() addAppleHealth = new EventEmitter();

  showAddAppleHealth = false;

  ngOnInit(): void {
    this.checkDeviceCapability();
  }

  get menuItemEnabled(): boolean {
    switch (this.menuItem?.icon) {
      case 'blood-pressure':
        return environment.features.selfMeasurements.bloodPressure;
      case 'body-heights':
        return environment.features.selfMeasurements.bodyHeight;
      case 'body-temperatures':
        return environment.features.selfMeasurements.bodyTemperature;
      case 'body-weights':
        return environment.features.selfMeasurements.bodyWeight;
      case 'glucoses':
        return environment.features.selfMeasurements.glucose;
      case 'heart-and-pulse-rates':
        return environment.features.selfMeasurements.heartAndPulseRates;
      case 'o2-saturations':
        return environment.features.selfMeasurements.o2saturations;
      case 'respiration':
        return environment.features.selfMeasurements.respiratory;
      default:
        return false;
    }
  }

  async checkDeviceCapability() {
    try {
      const info = await Device.getInfo();

      // For Apple Health we need to:
      // - Support: iOS devices and simulators running iOS 17.0+
      // - Not Support: Mac devices running iPad apps ("Designed for iPad")
      // Detection: Mac devices have 'macintosh' in userAgent but no 'mobile' keyword
      const isRunningOnMac =
        navigator.userAgent.toLowerCase().includes('macintosh') &&
        !navigator.userAgent.toLowerCase().includes('mobile');

      if (info.platform === 'ios' && !isRunningOnMac) {
        const iosVersion = parseFloat(info.osVersion);
        this.showAddAppleHealth = iosVersion >= 17.0;
      } else {
        // Hide for non-iOS devices
        this.showAddAppleHealth = false;
      }
    } catch (error) {
      this.showAddAppleHealth = false;
      void error;
    }
  }
}
