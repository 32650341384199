<ul class="nav nav-pills">
  <li class="nav-item"
      *ngFor="let item of menu">
    <a class="nav-link icon-link"
       [routerLinkActive]="['selected']"
       [routerLink]="item.path">
      <svg class="menu-icon"
           aria-hidden="true">
        <use attr.xlink:href="./assets/images/assets.svg#{{ item.icon }}"></use>
      </svg>
      {{ item.headerTopMenu }}
    </a>
  </li>
</ul>