<form [formGroup]="form">
  <div class="d-flex flex-column mb-3">
    <label for="dateInput"
           class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.date">Date</span> *</label>
    <input type="datetime-local"
           class="form-control"
           id="dateInput"
           formControlName="dateControl"
           [max]="maxDay"
           [class.is-invalid]="dateControl?.invalid && dateControl?.touched"
           required />
  </div>

  <div class="d-flex">

    <div class="d-flex flex-column flex-fill mb-3 me-3">
      <label for="heightInput"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.height">Height</span> *</label>
      <input type="text"
             class="form-control"
             id="heightInput"
             formControlName="heightControl"
             (input)="validateInput($event.target)"
             [class.is-invalid]="heightControl?.invalid && heightControl?.touched"
             required />
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="unitInput"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.unit">Unit</span> *</label>
      <select id="unitInput"
              formControlName="unitControl"
              class="form-control form-select"
              [class.is-invalid]="unitControl?.invalid && unitControl?.touched">
        <option selected
                value="cm">cm</option>
        <option value="[in_i]">inch</option>
      </select>
    </div>

  </div>

  <div class="d-flex flex-column border border-3 border-gray-300 rounded p-2">
    <div class="d-flex flex-column mb-3 col-form-label fs-6 fw-bolder">
      <span i18n="@@app.input.optional-additional-information">Optional Additional Information</span>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="positionControl"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.height.position">Position</span>
      </label>
      <select id="positionControl"
              class="form-control form-select"
              formControlName="positionControl">
        <option value=""
                selected><span i18n="@@app.input.select">- select -</span></option>
        <option value="8308-9"
                selected><span i18n="@@app.input.height.standing">Standing</span></option>
        <option value="8306-3"
                selected><span i18n="@@app.input.height.lying">Lying</span></option>

      </select>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="additionalCommentsInput"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.additionalComment">Additional Comments</span>
      </label>
      <textarea type="text"
                class="form-control text-truncate textarea-scroll"
                formControlName="additionalCommentsControl"
                maxlength="300"
                pattern="^[\s\S]+$"
                id="additionalCommentsInput"
                rows="2"></textarea>
    </div>
  </div>
</form>