<form [formGroup]="form">
  <div class="d-flex flex-column mb-3">
    <label for="dateInput"
           class="col-form-label fs-6 fw-bolder">
      <span i18n="@@app.input.date">Date</span> *
    </label>
    <input type="datetime-local"
           class="form-control"
           id="dateInput"
           formControlName="dateControl"
           [max]="maxDay"
           [class.is-invalid]="dateControl?.invalid && dateControl?.touched"
           required />
  </div>

  <div class="d-flex">
    <div class="d-flex flex-column flex-fill mb-3 me-3">
      <label for="breathsPerMinuteControl"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.respiration-rate.breaths-per-minute">Breaths per minute</span> *
      </label>
      <input type="text"
             class="form-control"
             id="breathsPerMinuteControl"
             formControlName="breathsPerMinuteControl"
             min="0"
             max="999"
             step="1"
             (input)="breathsPerMinuteControlInput($event.target)"
             [class.is-invalid]="breathsPerMinuteControl?.invalid && breathsPerMinuteControl?.touched"
             required />
    </div>
  </div>

  <div class="d-flex flex-column border border-3 border-gray-300 rounded p-2">
    <div class="d-flex flex-column mb-3 col-form-label fs-6 fw-bolder">
      <span i18n="@@app.input.optional-additional-information">Optional Additional Information</span>
    </div>
    <div class="
         d-flex
         flex-column
         mb-3">
      <label for="rhythmControl"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.respiration-rate.rhythm">Rhythm</span>
      </label>
      <select id="rhythmControl"
              class="form-control form-select"
              formControlName="rhythmControl">
        <option value=""
                selected>
          <span i18n="@@app.input.select">- select -</span>
        </option>
        <option *ngFor="let coding of translatedRitmeCodelijst"
                [value]="coding.code">{{ coding.display }}</option>
      </select>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="depthControl"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.respiration-rate.depth">Depth</span>
      </label>
      <select id="depthControl"
              class="form-control form-select"
              formControlName="depthControl">
        <option value=""
                selected>
          <span i18n="@@app.input.select">- select -</span>
        </option>
        <option *ngFor="let coding of translatedDiepteCodelijst"
                [value]="coding.code">{{ coding.display }}</option>
      </select>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="patternControl"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.respiration-rate.pattern">Pattern</span>
      </label>
      <select id="patternControl"
              class="form-control form-select"
              formControlName="patternControl">
        <option value=""
                selected>
          <span i18n="@@app.input.select">- select -</span>
        </option>
        <option *ngFor="let coding of translatedAfwijkendAdemhalingspatroonCodelijst"
                [value]="coding.code">{{ coding.display }}</option>
      </select>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="oxygenAdministeredControl"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.respiration-rate.administered-oxygen">Administered Oxygen</span>
      </label>
      <select id="oxygenAdministeredControl"
              class="form-control form-select"
              formControlName="oxygenAdministeredControl">
        <option value=""
                selected>
          <span i18n="@@app.input.select">- select -</span>
        </option>
        <option *ngFor="let coding of translatedJaNeeCodelijst"
                [value]="coding.code">{{ coding.display }}</option>
      </select>
    </div>

    <div class="d-flex flex-column mb-3"
         *ngIf="oxygenAdministeredControl?.value === 'Y'">
      <div class="d-flex">
        <div class="flex-fill me-3">
          <label for="flowRateControl"
                 class="col-form-label fs-6 fw-bolder">
            <span i18n="@@app.input.respiration-rate.flow-rate">Flow Rate (l/min)</span>
          </label>
          <input type="text"
                 class="form-control"
                 id="flowRateControl"
                 formControlName="flowRateControl"
                 min="0.0"
                 max="50.0"
                 step="0.1"
                 (input)="flowRateControlInput($event.target)"
                 [class.is-invalid]="flowRateControl?.invalid && flowRateControl?.touched" />
        </div>

        <div class="d-flex flex-column flex-fill mb-3">
          <label for="inspiredValueControl"
                 class="col-form-label fs-6 fw-bolder">
            <span i18n="@@app.input.respiration-rate.inspired-oxygen">Inspired Oxygen</span>
          </label>
          <input type="text"
                 class="form-control"
                 id="inspiredValueControl"
                 formControlName="inspiredValueControl"
                 min="0.0"
                 max="1.0"
                 step="0.01"
                 (input)="inspiredValueControlInput($event.target)"
                 [class.is-invalid]="inspiredValueControl?.invalid && inspiredValueControl?.touched" />
        </div>
      </div>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="additionalCommentsInput"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.additionalComment">Comments</span>
      </label>
      <textarea type="text"
                class="form-control text-truncate textarea-scroll"
                formControlName="additionalCommentsControl"
                maxlength="300"
                pattern="^[\s\S]+$"
                id="additionalCommentsInput"
                rows="2"></textarea>
    </div>
  </div>
</form>