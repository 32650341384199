import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { addIf } from '@globals';
import { ButtonComponent } from '@layout';
import { MenuItem } from '@navigation/menu';
import { SharedModule } from '@shared';

@Component({
  selector: 'app-self-measurements-menu',
  templateUrl: './self-measurements-menu.component.html',
  standalone: true,
  imports: [SharedModule, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfMeasurementsMenuComponent {
  @Output() selected = new EventEmitter<MenuItem>();
  menu: MenuItem[] = [
    ...addIf(environment.features.selfMeasurements.bloodPressure, {
      header: $localize`:@@app.menu.bloodPressure:Blood pressure`,
      icon: 'blood-pressure',
    }),
    ...addIf(environment.features.selfMeasurements.bodyWeight, {
      header: $localize`:@@app.menu.bodyWeight:Body Weight`,
      icon: 'body-weights',
    }),
    ...addIf(environment.features.selfMeasurements.bodyHeight, {
      header: $localize`:@@app.menu.bodyHeight:Body Height`,
      icon: 'body-heights',
    }),
    ...addIf(environment.features.selfMeasurements.bodyTemperature, {
      header: $localize`:@@app.menu.bodyTemperature:Body Temperature`,
      icon: 'body-temperatures',
    }),
    ...addIf(environment.features.selfMeasurements.heartAndPulseRates, {
      header: $localize`:@@app.menu.heartAndPulseRates:Heart and Pulse Rates`,
      icon: 'heart-and-pulse-rates',
    }),
    ...addIf(environment.features.selfMeasurements.glucose, {
      header: $localize`:@@app.menu.glucose:Glucose`,
      icon: 'glucoses',
    }),
    ...addIf(environment.features.selfMeasurements.o2saturations, {
      header: $localize`:@@app.menu.o2Saturation:O2 Saturation`,
      icon: 'o2-saturations',
    }),
    ...addIf(environment.features.selfMeasurements.respiratory, {
      header: $localize`:@@app.menu.respiration:Respiration`,
      icon: 'respiration',
    }),
  ];

  select(item: MenuItem): void {
    this.selected.emit(item);
  }
}
