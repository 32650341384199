<form [formGroup]="form">
  <div class="d-flex flex-column mb-3">
    <label for="dateInput"
           class="col-form-label fs-6 fw-bolder">
      <span i18n="@@app.input.date">Date</span> *
    </label>
    <input type="datetime-local"
           class="form-control"
           id="dateInput"
           formControlName="dateControl"
           [max]="maxDay"
           [class.is-invalid]="dateControl?.invalid && dateControl?.touched"
           required />
  </div>

  <div class="d-flex">
    <div class="d-flex flex-column flex-fill mb-3 me-3">
      <label for="bloodOxygenControl"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.blood-oxygen">Blood Oxygen (%)</span> *
      </label>
      <input type="text"
             class="form-control"
             id="bloodOxygenControl"
             formControlName="bloodOxygenControl"
             (input)="bloodOxygenControlInput($event.target)"
             [class.is-invalid]="bloodOxygenControl?.invalid && bloodOxygenControl?.touched"
             required />
    </div>
  </div>

  <div class="d-flex flex-column border border-3 border-gray-300 rounded p-2">
    <div class="d-flex flex-column mb-3 col-form-label fs-6 fw-bolder">
      <span i18n="@@app.input.optional-additional-information">Optional Additional Information</span>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="oxygenAdministeredControl"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.blood-oxygen.administered-oxygen">Administered Oxygen</span>
      </label>
      <select id="oxygenAdministeredControl"
              class="form-control form-select"
              formControlName="oxygenAdministeredControl">
        <option value=""
                selected>
          <span i18n="@@app.input.select">- select -</span>
        </option>
        <option *ngFor="let coding of translatedJaNeeCodelijst"
                [value]="coding.code">{{ coding.display }}</option>
      </select>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="additionalCommentsInput"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.additionalComment">Comments</span>
      </label>
      <textarea type="text"
                class="form-control text-truncate textarea-scroll"
                formControlName="additionalCommentsControl"
                maxlength="300"
                pattern="^[\s\S]+$"
                id="additionalCommentsInput"
                rows="2"></textarea>
    </div>
  </div>
</form>