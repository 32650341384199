import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppLanguageService, Language } from '@core/services';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { FRESHDESK_ACTIONS, selectIsAuthenticated } from '@store/app';
import { USER_ACTIONS } from '@store/user';

@Component({
  selector: 'app-help-drop-down-menu',
  templateUrl: './help-drop-down-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpDropDownMenuComponent {
  @ViewChild('drop', { static: true }) dropdown: NgbDropdown | undefined;

  readonly isAuthenticated$ = this.store.select(selectIsAuthenticated);
  readonly locale = $localize.locale;

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly appLanguageService: AppLanguageService,
  ) {}

  close() {
    this.dropdown?.close();
  }

  settings(): void {
    this.close();
    this.router.navigate([`${$localize.locale}`, 'settings']);
  }

  reportButtonClicked(): void {
    this.close();
    this.store.dispatch(FRESHDESK_ACTIONS.openWidget());
  }

  changeLanguage(lang: Language): void {
    this.appLanguageService.changeLanguage(lang);
  }

  logout(): void {
    this.store.dispatch(USER_ACTIONS.signOut());
  }
}
