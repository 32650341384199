<form [formGroup]="form">
  <div class="d-flex flex-column mb-3">
    <label for="dateInput"
           class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.date">Date</span> *</label>
    <input type="datetime-local"
           class="form-control"
           id="dateInput"
           formControlName="dateControl"
           [max]="maxDay"
           [class.is-invalid]="dateControl?.invalid && dateControl?.touched"
           required />
  </div>

  <div class="d-flex">

    <div class="d-flex flex-column flex-fill mb-3 me-3">
      <label for="temperatureInput"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.temperature">Temperature</span> *</label>
      <input type="text"
             class="form-control"
             id="temperatureInput"
             formControlName="temperatureControl"
             (input)="validateInput($event.target)"
             [class.is-invalid]="temperatureControl?.invalid && temperatureControl?.touched"
             required />
    </div>
  </div>

  <div class="d-flex flex-column border border-3 border-gray-300 rounded p-2">
    <div class="d-flex flex-column mb-3 col-form-label fs-6 fw-bolder">
      <span i18n="@@app.input.optional-additional-information">Optional Additional Information</span>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="methodInput"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.method">Method</span>
      </label>
      <select id="methodInput"
              class="form-control form-select"
              formControlName="methodControl">
        <option value=""
                selected><span i18n="@@app.input.select">- select -</span></option>
        <option *ngFor="let coding of translatedTemperatureTypeCodelist"
                [value]="coding.code">{{ coding.display }}</option>

      </select>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="additionalCommentsInput"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.additionalComment">Additional Comments</span>
      </label>
      <textarea type="text"
                class="form-control text-truncate textarea-scroll"
                formControlName="additionalCommentsControl"
                maxlength="300"
                pattern="^[\s\S]+$"
                id="additionalCommentsInput"
                rows="2"></textarea>
    </div>
  </div>
</form>