import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getMaxDay, limitAndFormatDecimal, nameof } from '@globals';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-body-height-entry',
  templateUrl: './body-height-entry.component.html',
  imports: [SharedModule, ButtonComponent],
})
export class BodyHeightEntryComponent {
  @Input({ required: true }) form!: FormGroup;

  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>();

  get maxDay(): string {
    return getMaxDay();
  }

  get dateControl() {
    return this.form.get(nameof<BodyHeightEntryComponent>('dateControl'));
  }

  get unitControl() {
    return this.form.get(nameof<BodyHeightEntryComponent>('unitControl'));
  }

  get heightControl() {
    return this.form.get(nameof<BodyHeightEntryComponent>('heightControl'));
  }

  /**
   * Limits the input value of a target element to a maximum available number and a specific decimal pattern.
   * @param target - The target element that triggered the event.
   */
  validateInput(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    const value = limitAndFormatDecimal(input.value, 999.99, 0.01);
    this.heightControl!.setValue(value);
  }
}
