import * as r3 from 'fhir/r3';

export const BLOOD_GLUCOSE_TEMPLATE: r3.Observation = {
  resourceType: 'Observation',

  id: '$UUID_CREATED_FOR_THE_RESOURCE',
  identifier: [
    {
      system: 'urn:oid:$OID_FOR_DM_PRODUCT',
      value: 'urn:uuid:UUID_CREATED_FOR_THE_RESOURCE',
    },
  ],
  effectiveDateTime: '$DATETIME_COLLECTED_FROM_USER',
  category: [
    {
      coding: [
        {
          display: 'Vital Signs',
          system: 'http://hl7.org/fhir/observation-category',
          code: 'vital-signs',
        },
      ],
    },
  ],
  subject: {
    display: 'Patient',
    reference: 'Patient/urn:uuid:$UUID_FOR_PATIENT',
  },
  performer: [
    {
      display: 'Patient',
      reference: 'Patient/urn:uuid:$UUID_FOR_PATIENT',
    },
  ],
  status: 'final',

  meta: {
    profile: [
      'http://nictiz.nl/fhir/StructureDefinition/vitalsign-bloodglucose',
      'http://hl7.org/fhir/3.0/StructureDefinition/Observation',
    ],
  },

  valueQuantity: {
    code: 'mmol/L',
    value: '$VALUE' as any,
    unit: 'mmol/L',
    system: 'http://unitsofmeasure.org',
  },

  code: {
    coding: [
      {
        display: 'Glucose [mol/volume] in capillary blood by glucometer',
        system: 'http://loinc.org',
        code: '14743-9',
      },
    ],
  },

  // This is example optional data
  /*extension: [
    {
      url: 'http://hl7.org/fhir/StructureDefinition/observation-eventTiming',
      extension: [
        {
          valueCodeableConcept: {
            coding: [
              {
                code: '$TIMING_CODE',
                system: 'http://hl7.org/fhir/ValueSet/v3-TimingEvent',
                display: '$TIMING_DISPLAY',
              },
            ],
          },
          url: 'offset',
        },
      ],
    },
  ],*/
};
